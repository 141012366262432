import styled from 'styled-components';

const FooterStyle = styled.footer`
	--padT: var(--sp15x);
	--padB: var(--sp5x);
	--padLR: var(--sp20x);
	--navGap: var(--sp22x);
	--navItemsMarginT: var(--sp2x);
	--navPItemMarginT: var(--sp3x);
	--wrappersPadTB: var(--sp5x);
	--wrapperTPadB: var(--sp12x);
	--socialsGap: var(--sp3x);
	--footerInputPadTB: var(--sp2x);
	--footerInputPadLR: var(--sp3-5x);
	--sizeArrowSubscribe: var(--sp3x);

	background-color: var(--backgroundColor);

	.wrapper-outside-footer {
		padding: var(--padT) var(--padLR) 0;
	}

	.empty-line-wrapper {
		width: 100%;
		height: 2px;
		padding: 0 var(--padLR);

		.empty-line {
			width: 100%;
			height: 2px;
			background-color: var(--white);
		}
	}

	.item-nav {
		display: block;
		width: fit-content;
	}

	.footer-nav-links {
		display: flex;
		flex-direction: column;

		a,
		.wantToStayUp {
			margin-top: var(--navItemsMarginT);
		}

		.item-nav {
			margin-top: var(--navItemsMarginT);

			a {
				margin-top: 0;
			}
		}

		.link-item {
			width: fit-content;
			text-transform: capitalize;
		}

		p,
		.materials-link {
			margin-bottom: var(--navPItemMarginT);
			margin-top: 0;
		}
		.material-nav {
			margin-top: 0;
		}

		a {
			width: fit-content;
			text-decoration: none;
			position: relative;
		}
	}

	p,
	a {
		will-change: transform;
	}

	.wrapper-top-footer {
		padding-bottom: var(--wrapperTPadB);
	}

	.wrapper-middle {
		border-top: 2px solid white;
		align-items: center;
	}

	.wrapper-bottom {
		border-top: 2px solid white;
		align-items: center;

		.item-nav {
			display: inline;
		}
	}

	.wrapper-bottom,
	.wrapper-middle {
		padding: var(--wrappersPadTB) 0;
	}

	.social-media {
		display: flex;
		justify-content: end;
		gap: var(--socialsGap);
	}

	.image-cont {
		--proportion: 100%;
	}

	.footer-icon {
		background-color: var(--blue1000);
		border-radius: var(--sp1x);
		padding: 6px 14px;
		display: flex;
		align-items: center;
		i {
			color: var(--white) !important;
		}
	}

	.link-item-navigation {
		transition: var(--trTime) ease-out;
	}

	.contact-wrapper-footer {
		margin-top: var(--distanceFormT);

		.form-container {
			position: relative;

			.ant-input-affix-wrapper {
				border: none;
				border-radius: var(--sp5x);
				padding: unset;
				display: flex;
				justify-content: end;
				align-items: center;
				position: relative;

				.ant-input-prefix {
					position: absolute;
					z-index: 1;
				}
			}
		}
	}

	.social-media {
		display: flex;
		gap: var(--socialsGap);

		.item-nav {
			transition: var(--trTime) ease-out;
		}
	}

	.footer-subscribe-icon {
		font-size: var(--p3);
		color: var(--blue900);
		cursor: pointer;

		&.hide {
			opacity: 0;
		}
	}

	.button-submit {
		> button {
			position: relative;
		}
	}

	.loader-block {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: var(--footerInputPadLR);
	}

	.subscribe-field {
		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	.form-container {
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover,
		textarea:-webkit-autofill:focus,
		textarea:-webkit-autofill:active,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus,
		select:-webkit-autofill:active,
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active,
		input:-internal-autofill-selected {
			box-shadow: inset 1000px 1000px none;
			-webkit-text-fill-color: var(--blue900);
			transition: background-color 5000s ease-in-out 0s;
		}

		input {
			background-color: var(--white);
			border: none;
			font-family: var(--montserrat_arm) !important;
			font-weight: 400;
			padding: var(--footerInputPadTB) var(--footerInputPadLR);
			padding-right: calc(var(--footerInputPadLR) + calc((var(--p3) * var(--lineHeightL))) * 1.5) !important;
			border-radius: var(--sp5x);
			color: var(--blue900);
			transition: var(--trTime) ease-out;
			width: 100%;
			font-size: var(--pInput);
			line-height: 1 !important;

			&:focus {
				color: var(--blue900);
			}
		}

		span,
		input {
			&:focus-within {
				box-shadow: none !important;
			}
		}

		.ant-form-item-explain {
			transform: translateY(calc(var(--navPItemMarginT) / 2));
		}

		.ant-input-prefix {
			margin: 0;
			right: var(--footerInputPadLR);
			i {
				font-size: var(--sizeArrowSubscribe);
				background-color: transparent !important;
			}

			i:before {
				transition: color var(--trTime) ease-out;
			}
		}

		.ant-form-item-has-error {
			.ant-input-prefix {
				i:before {
					color: var(--errorColor);
				}
			}
		}

		.ant-form-item {
			margin-bottom: 0;
		}

		.subscribe-text {
			margin-top: calc(var(--navPItemMarginT) / 2);
		}
	}

	@media (hover: hover) {
		.ant-input-affix-wrapper {
			&:hover {
				input {
					color: var(--grayInput);
					background-color: var(--white);
				}
			}

			&:focus-within:hover {
				input {
					color: var(--blue900);
				}
			}
		}

		.link-item-navigation {
			width: fit-content;

			&:hover {
				color: var(--lightBlue);
			}
		}

		.social-media {
			.item-nav {
				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}

	.mobile-news-wrapper {
		display: none;
	}

	//! Custom Size */
	@media only screen and (max-width: 2559px) {
		.footer-icon {
			padding-left: var(--sp1-5x);
			padding-right: var(--sp1-5x);
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--padT: var(--sp10x);
		--padB: var(--sp4x);
		--padLR: var(--sp17x);
		--navGap: var(--sp18x);
		--wrappersPadTB: var(--sp4x);
		--wrapperTPadB: var(--sp10x);
		--socialsGap: var(--sp2x);
		--footerInputPadTB: var(--sp1-5x);
		--footerInputPadLR: var(--sp3x);
		--sizeArrowSubscribe: var(--sp2x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--padT: var(--sp7x);
		--padB: var(--sp3-5x);
		--padLR: var(--sp11x);
		--navGap: var(--sp14x);
		--navItemsMarginT: var(--sp1-5x);
		--navPItemMarginT: var(--sp2-5x);
		--wrappersPadTB: var(--sp3-5x);
		--wrapperTPadB: var(--sp9x);
		--socialsGap: var(--sp1-5x);
		--sizeArrowSubscribe: var(--sp2x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--padT: var(--sp6x);
		--padB: var(--sp3x);
		--padLR: var(--sp9x);
		--navGap: var(--sp11x);
		--wrappersPadTB: var(--sp3x);
		--wrapperTPadB: var(--sp7x);
		--footerInputPadLR: var(--sp2-5x);
		--sizeArrowSubscribe: var(--sp2x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--padT: var(--sp6x);
		--padB: var(--sp3x);
		--padLR: var(--sp8x);
		--navGap: var(--sp11x);
		--navItemsMarginT: var(--sp1x);
		--navPItemMarginT: var(--sp2x);
		--footerInputPadTB: var(--sp2x);
		--sizeArrowSubscribe: calc(var(--sp0-5x) / 2 + var(--sp1-5x));

		.footer-icon {
			padding: 2px 5px;
			i {
				font-size: var(--h3);
			}
		}
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--padT: var(--sp15x);
		--padB: var(--sp4x);
		--padLR: var(--sp6x);
		--navGap: var(--sp8x);
		--wrapperTPadB: var(--sp5x);

		.contact-wrapper-footer {
			margin-top: var(--sp1x);
		}

		.footer-nav {
			justify-content: space-between;
		}

		.subscribe-wrapper {
			margin-top: var(--sp6x);
		}

		.wrapper-middle {
			align-items: end;
		}

		.wrapper-bottom {
			padding: var(--sp4x) 0;
			.rights {
				text-align: left;
			}
		}

		.rights {
			margin-top: var(--sp2x);
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padT: var(--sp4x);
		--padB: var(--sp3x);
		--padLR: var(--sp2x);
		--navGap: var(--sp8x);

		.news-nav-link {
			display: none;
		}

		.mobile-news-wrapper {
			display: block;
			margin-top: var(--sp3x);
		}

		.social-media {
			justify-content: start;
		}

		.footer-logo-wrapper {
			margin-bottom: var(--sp6x);
		}

		.wrapper-middle {
			div {
				margin-top: var(--sp1x);
				&:nth-child(1) {
					margin-top: unset;
				}
			}
		}

		.rights {
			margin-top: var(--sp1x);
		}

		.footer-nav-links {
			&:nth-child(3) {
				margin-top: -28px;
			}
		}
	}
`;

export default FooterStyle;
