import styled from 'styled-components';

const PageStyle = styled.main`
	> :first-child {
		padding-top: var(--headerFixedHeight);
	}

	#home {
		padding-top: unset !important;
	}

	.link-animation-underline {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: var(--lightBlue);
			transition: all 0.7s ease;
		}

		&[hovered='1'] {
			&:after {
				animation: disappearRight1 0.3s ease-out, appearLeft1 0.3s 0.3s ease-out forwards;
			}
		}
	}

	@keyframes arrowAnim {
		50% {
			transform: translateX(20%) rotate(-90deg);
		}
		100% {
			transform: translateX(0%) rotate(-90deg);
		}
	}

	@keyframes disappearRight {
		0% {
			left: 0;
			width: 100%;
		}
		100% {
			left: 100%;
			width: 0;
		}
	}

	@keyframes appearLeft {
		0% {
			left: 0;
			width: 0;
		}
		100% {
			left: 0;
			width: 100%;
		}
	}

	@keyframes disappearRight1 {
		0% {
			left: 0;
			width: 100%;
		}
		100% {
			left: 100%;
			width: 0;
		}
	}

	@keyframes appearLeft1 {
		0% {
			left: 0;
			width: 0;
		}
		100% {
			left: 0;
			width: 100%;
		}
	}

	@media (hover: hover) {
		.arrow-animation-left-right {
			&:hover {
				.arrow-icon {
					animation: arrowAnim 0.5s infinite ease-out;
				}
			}
		}

		.link-animation-underline {
			&:hover {
				&:after {
					animation: disappearRight 0.3s ease-out, appearLeft 0.3s 0.3s ease-out forwards;
				}
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
	}
`;

export default PageStyle;
