import styled from 'styled-components';

const NavigationStyle = styled.div`
	--padNavigation: var(--sp1x);
	--radiusBorder: var(--sp3x);
	--distanceNavigationItem: var(--sp2x);
	--everyLinkPadTB: var(--sp2x);
	--everyLinkPadLR: var(--sp2x);
	--sizeIcon: var(--sp3x);

	--burgerMenuPadding: var(--sp2-5x);
	--burgerBorderRadius: var(--sp2x);
	--distanceTitleNavigation: var(--sp2-5x);

	--radiusCollapse: var(--sp2x);

	display: flex;
	align-items: center;

	&.burger-navigation {
		--padNavigation: 0;
		--radiusBorder: 0;
		--everyLinkPadTB: 0;
		--everyLinkPadLR: 0;

		flex-direction: column;
		align-items: flex-start;
		border-radius: var(--burgerBorderRadius);
		padding: var(--burgerMenuPadding);
		background-color: var(--white);
		gap: var(--distanceTitleNavigation);

		.wrapper-collapse {
			position: relative;
			height: fit-content;
			pointer-events: all !important;
		}

		.inner-wrapper-collapse {
			padding: 0;
			background-color: transparent;
		}

		.text-navigation {
			font-size: var(--h5);
			color: var(--blue1000);
		}

		.every-link-collapse {
			width: fit-content;
			font-size: var(--h6);
			font-weight: 400;
			color: var(--blue1000);
		}
	}

	.wrapper-collapse {
		position: absolute;
		top: 100%;
		height: 0;
		overflow: hidden;
		pointer-events: none;
		transform: translateX(calc(-1 * (var(--everyLinkPadLR) + var(--padNavigation))));

		&.pointer-all {
			pointer-events: all;
		}
	}

	.inner-wrapper-collapse {
		border-radius: var(--radiusBorder);
		background-color: var(--white);
		padding: var(--padNavigation);
		margin-top: 1px;
		display: flex;
		flex-direction: column;
		gap: var(--distanceNavigationItem);
	}
	.wrapper-link {
		position: relative;
		z-index: 1;
	}

	.every-link-collapse {
		transition: color var(--trTime) ease-out;

		padding: var(--everyLinkPadTB) var(--everyLinkPadLR);

		display: block;
		width: 100%;
		height: 100%;

		&.active {
			color: var(--lightBlue);
		}
	}

	.wrapper-name {
		display: flex;
		align-items: center;
		will-change: transform;
		cursor: pointer;

		.arrow-icon {
			transform: rotate(0deg);
			transition: all var(--trTime) ease-out;
			color: var(--blue900);
			font-size: var(--sizeIcon);
			margin-left: var(--sp0-5x);

			&.active {
				transform: rotate(180deg);
			}
		}
	}

	.selected-bar-animation {
		width: calc(100% - var(--padNavigation) * 2);
		position: absolute;
		background-color: var(--lightBlue);
		border-radius: var(--radiusCollapse);
		/* opacity: 0.1; */
		pointer-events: none !important;

		.inner-no-text {
			width: 100%;
			opacity: 0;
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--distanceNavigationItem: calc(var(--sp2x) - var(--sp0-5x) / 2);
		--everyLinkPadLR: calc(var(--sp2x) - var(--sp0-5x) / 2);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--radiusBorder: var(--sp2-5x);
		--distanceNavigationItem: var(--sp1-5x);
		--everyLinkPadLR: var(--sp1-5x);
		--everyLinkPadTB: var(--sp1-5x);
		--sizeIcon: var(--sp2-5x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--radiusBorder: var(--sp2x);
		--padNavigation: calc(var(--sp1x) - var(--sp0-5x) / 2);
		--radiusCollapse: var(--sp1x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--distanceNavigationItem: var(--sp2x);

		.wrapper-name {
			cursor: default;
		}
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--burgerMenuPadding: var(--sp2x);
		--burgerBorderRadius: calc(var(--sp1-5x) - var(--sp0-5x) / 2);
		--distanceNavigationItem: calc(var(--sp2x) - var(--sp0-5x) / 2);
	}
`;
export default NavigationStyle;
