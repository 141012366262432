import { useMemo, useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Style
import LanguageSwitcherStyle from './style';

//* Components
import Text from '@/components/Text';

const LanguageSwitcher = ({ selectedLang, languages }) => {
	//! State
	const [isDropDownActive, setIsDropdownActive] = useState(false);

	//! Next Navigation
	const pathname = usePathname();

	const chooseLanguage = useMemo(() => {
		const route = pathname.replace(`/${selectedLang}`, '');
		return Object.keys(languages).map((lang) => {
			return selectedLang != lang ? (
				<div
					key={lang}
					className='lang-item'>
					<Link
						as={`/${lang}${route}`}
						href={`/[lang]${route}`}>
						<Text
							className={'p2 text-language font-montserrat-medium uppercase text-navigation'}
							text={languages[lang].params.name}
						/>
					</Link>
				</div>
			) : null;
		});
	}, [selectedLang, languages, pathname]);

	//! Check className
	const checkClass = (el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`);

	//! Detect click outside
	const handleClickOutside = (e) => {
		if (checkClass(e.target, 'selected-lang-wrap') && !isDropDownActive) {
			setIsDropdownActive(true);
		} else if (!checkClass(e.target, 'dropdown') && isDropDownActive) {
			setIsDropdownActive(false);
		}
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDropDownActive]);

	return (
		<LanguageSwitcherStyle>
			<div className='selected-lang-wrap'>{chooseLanguage}</div>
		</LanguageSwitcherStyle>
	);
};

export default withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']);
