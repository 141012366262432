import { useCallback, useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
import gsap from 'gsap/all';

//* Styles
import BurgerMenuStyle from './style';

//* HOO'C
import { withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import burger from '@/lottieFiles/burgerMenu.json';

//* Component
import LottieAnimation from '../../LottieAnimation';
import Navigation from '../Navigation';
import CustomLink from '@/components/CustomLink';

const BurgerMenu = ({ activePath, toggleBurgerMenu, isBurgerMenuOpen, translate, closeBurgerMenu, winWidth }) => {
	//! Ref
	const menuOpenRef = useRef();
	const wrapperBurgerOpen = useRef();
	const requestBurgerRef = useRef();
	const openMenuAnimationRef = useRef();
	const timelineSet = useRef();

	const pathname = usePathname();

	useEffect(() => {
		if (isBurgerMenuOpen) {
			closeBurgerMenu();
		}
	}, [pathname]);

	const handleWindowClick = useCallback(
		(e) => {
			if (requestBurgerRef.current?.contains(e.target) && !requestBurgerRef.current.classList?.contains('.active-link')) {
				closeBurgerMenu();
			}
			if (!menuOpenRef.current?.contains(e.target) && wrapperBurgerOpen.current?.contains(e.target)) {
				closeBurgerMenu();
			}
		},
		[requestBurgerRef]
	);

	useEffect(() => {
		window.addEventListener('click', handleWindowClick);

		return () => {
			window.removeEventListener('click', handleWindowClick);
		};
	}, []);

	useEffect(() => {
		if (winWidth >= 1280 && isBurgerMenuOpen) {
			closeBurgerMenu();
		}
	}, [winWidth]);

	useEffect(() => {
		if (menuOpenRef.current && isBurgerMenuOpen) {
			openMenuAnimationRef.current = gsap.timeline();

			openMenuAnimationRef.current.to(menuOpenRef.current.children, {
				delay: 0.3,
				ease: 'power2.out',
				duration: 0.6,
				y: 0,
				stagger: 0.1,
			});
		}

		if (menuOpenRef.current && !isBurgerMenuOpen) {
			timelineSet.current = gsap.timeline();
			const gapValue = parseInt(getComputedStyle(menuOpenRef.current).gap);
			timelineSet.current.set(menuOpenRef.current.children, { y: `${gapValue * 4}`, duration: 0, delay: 0.3 });
		}

		return () => {
			if (openMenuAnimationRef.current) {
				openMenuAnimationRef.current.kill();
				openMenuAnimationRef.current = null;
			}
			if (timelineSet.current) {
				timelineSet.current.kill();
				timelineSet.current = null;
			}
		};
	}, [isBurgerMenuOpen]);

	return (
		<BurgerMenuStyle>
			<div className='burger-menu-inner'>
				<div
					ref={wrapperBurgerOpen}
					className={`wrapper-menu-open ${isBurgerMenuOpen ? 'active' : ''}`}>
					<div className='inner-menu'>
						<div
							ref={menuOpenRef}
							className={`opened-menu menu-burger-bar ${isBurgerMenuOpen ? 'active' : ''}`}>
							<div>
								<Navigation
									active={activePath}
									isBurgerMenu
									className={'navigation-menu navigation-menu-sme'}
									name='smeDevelopment'
									data={[
										{ ...config.routes.about, name: translate(config.routes.about.name) },
										{ ...config.routes.team, name: translate(config.routes.team.name) },
										{ ...config.routes.reports, name: translate(config.routes.reports.name) }
									]}
								/>
							</div>
							<div>
								<Navigation
									active={activePath}
									isBurgerMenu
									className={'navigation-menu'}
									name='icArmenia'
									data={[
										{ ...config.routes.reforms, name: translate(config.routes.reforms.name) },
										{ ...config.routes.structure, name: translate(config.routes.structure.name) },
										{ ...config.routes.contact, name: translate(config.routes.contact.name) },
										{ ...config.routes.councilMeetings, name: translate(config.routes.councilMeetings.name) },
										{ ...config.routes.sustainabilityCorner, name: translate(config.routes.sustainabilityCorner.name) }
									]}
								/>
							</div>

							<div className='wrapper-link-burger'>
								<CustomLink
									className={`p1 text-navigation item-navigation-burger  font-montserrat-arm-medium ${activePath == config.routes.news.path ? 'active' : ''}`}
									url={config.routes.news.path}>
									{translate(config.routes.news.name)}
								</CustomLink>
							</div>
							<div className='wrapper-link-burger'>
								<CustomLink
									className={`p1 text-navigation  item-navigation-burger font-montserrat-arm-medium  ${activePath == config.routes.usefulMaterials.path ? 'active' : ''}`}
									url={config.routes.usefulMaterials.path}>
									{translate(config.routes.usefulMaterials.name)}
								</CustomLink>
							</div>
						</div>
					</div>
				</div>

				<div
					className='lottie-wrap text-navigation'
					onClick={() => toggleBurgerMenu()}>
					<LottieAnimation
						autoplay={false}
						isPlay={isBurgerMenuOpen}
						isReverse={!isBurgerMenuOpen}
						loop={false}
						animData={burger}
					/>
				</div>
			</div>
		</BurgerMenuStyle>
	);
};

export default withLanguageContext(withUIContext(BurgerMenu, ['toggleBurgerMenu', 'closeBurgerMenu', 'isBurgerMenuOpen', 'winWidth']), ['translate']);
