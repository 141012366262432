'use client';

import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap/all';

//* Helpers
import { config } from '@/helpers';

//* HOC's
import { withDataContext, withLanguageContext } from '@/context';

//* Components
import Text from '../Text';
import Icon from '../Icon';
import Logo from '../Logo';
import Resize from '../Resize';
import Button from '../Button';
import CustomLink from '../CustomLink';
import FormItem from '../Form/FormItem';
import ButtonLoading from '../ButtonLoading';
import FormContainer from '../Form/FormContainer';

const FormInput = dynamic(() => import('../Form/FormInput'));

//* Style
import FooterStyle from './style';

const AppFooter = ({ translate, globalData, subscribe, selectedLang }) => {
	//! State
	const [resize, setResize] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	//! Ref
	const wrapperTopFooter = useRef();
	const wrapperFooter = useRef();
	const scrollTrigger = useRef();
	const middleFooter = useRef();
	const calculatedPageHeight = useRef(false);
	const firstOpened = useRef(true);

	//! Pathname
	const pathname = usePathname();

	//! Handle Finish
	const handleSubscribe = (values) => {
		setIsSuccess('loading');

		return subscribe({ email: values.subscribe_email })
			.then(() => {
				setIsSuccess(true);

				setTimeout(() => {
					setIsSuccess(false);
				}, 3000);

				return Promise.resolve();
			})
			.catch((err) => {
				setIsSuccess(false);

				err['data']['errors']['subscribe_email'] = err['data']['errors']['email'];

				return Promise.reject(err);
			});
	};

	useEffect(() => {
		if (wrapperFooter.current && calculatedPageHeight.current && firstOpened.current) {
			const heightFirstItem = wrapperTopFooter.current.children[0].children[0].clientHeight;
			[...wrapperTopFooter.current.children].forEach((item) => {
				[...item.children].forEach((element) => {
					gsap.set(element, { y: `${heightFirstItem}px` });
				});
			});

			gsap.set(middleFooter.current, { y: '50%' });
			gsap.to(wrapperFooter.current, { opacity: 1, duration: 0, delay: 0 });

			scrollTrigger.current = ScrollTrigger.create({
				trigger: wrapperFooter.current,
				start: 'top bottom ',
				end: 'top top',
				once: true,
				onEnter: () => {
					[...wrapperTopFooter.current.children].forEach((item) => {
						[...item.children].forEach((element, index) => {
							gsap.to(element, { y: 0, duration: 0.6, ease: 'power2.out', delay: `${0.1 * index}` });
						});
					});
					gsap.to(middleFooter.current, { y: 0, duration: 0.6, ease: 'power2.out', delay: `${0.1 * wrapperTopFooter.current.children.length}` }).then(() => {
						firstOpened.current = false;
					});
				},
			});
		}
		return () => {
			if (scrollTrigger.current) {
				scrollTrigger.current.kill();
				scrollTrigger.current = null;
			}
		};
	}, [resize]);

	useEffect(() => {
		if (wrapperFooter.current) {
			const main = document.querySelector('main');
			// main.style.minHeight = `${window.innerHeight - wrapperFooter.current.clientHeight}px`;
			calculatedPageHeight.current = true;
		}
	}, []);

	return (
		<Resize
			main
			setResize={setResize}>
			<FooterStyle
				ref={wrapperFooter}
				className={`footer-container opacity-0`}>
				{pathname.replace('/' + selectedLang, '') !== config.routes.home.path ? (
					<div className='empty-line-wrapper'>
						<div className='empty-line' />
					</div>
				) : null}
				<div className='wrapper-outside-footer'>
					<div className='wrapper-footer row'>
						<div className='col-1 col-t-2 col-m-3 footer-logo-wrapper'>
							<Logo type={'blue-footer'} />
						</div>

						<div className='col-1 col-t-1 col-m-9'></div>

						<div className='col-10 col-t-9 col-m-12'>
							<div
								ref={wrapperTopFooter}
								className='wrapper-top-footer row'>
								<div className='col-3 col-t-4 col-m-6 footer-nav-links'>
									<Text
										className={'p2 font-montserrat-arm-medium blue-1000'}
										text={'icArmenia'}
									/>

									<span className='item-nav'>
										<CustomLink
											className={`p3 font-montserrat-arm-regular blue-900 underline link-item-navigation `}
											url={config.routes.about.path}>
											{translate(config.routes.about.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={`p3 font-montserrat-arm-regular blue-900 underline link-item-navigation `}
											url={config.routes.team.path}>
											{translate(config.routes.team.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={`p3 font-montserrat-arm-regular blue-900 underline link-item-navigation `}
											url={config.routes.reports.path}>
											{translate(config.routes.reports.name)}
										</CustomLink>
									</span>
									<div className='mobile-news-wrapper'>
										<span className='item-nav material-nav'>
											<CustomLink
												className={`p2 font-montserrat-arm-medium blue-1000 materials-link underline link-item-navigation `}
												url={config.routes.usefulMaterials.path}>
												{translate(config.routes.usefulMaterials.name)}
											</CustomLink>
										</span>

										<span className='item-nav'>
											<CustomLink
												className={`p2 font-montserrat-arm-medium blue-1000 underline link-item-navigation `}
												url={config.routes.news.path}>
												{translate(config.routes.news.name)}
											</CustomLink>
										</span>
									</div>
								</div>
								<div className='col-3 col-t-4 col-m-6 footer-nav-links'>
									<Text
										className={'p2 font-montserrat-arm-medium blue-1000'}
										text={'smeDevelopment'}
									/>

									<span className='item-nav'>
										<CustomLink
											className={'p3 font-montserrat-arm-regular blue-900 underline link-item-navigation'}
											url={config.routes.reforms.path}>
											{translate(config.routes.reforms.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={'p3 font-montserrat-arm-regular blue-900 underline link-item-navigation'}
											url={config.routes.structure.path}>
											{translate(config.routes.structure.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={'p3 font-montserrat-arm-regular blue-900 underline link-item-navigation'}
											url={config.routes.contact.path}>
											{translate(config.routes.contact.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={'p3 font-montserrat-arm-regular blue-900 underline link-item-navigation'}
											url={config.routes.councilMeetings.path}>
											{translate(config.routes.councilMeetings.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={'p3 font-montserrat-arm-regular blue-900 underline link-item-navigation'}
											url={config.routes.sustainabilityCorner.path}>
											{translate(config.routes.sustainabilityCorner.name)}
										</CustomLink>
									</span>
								</div>
								<div className='col-3 col-t-4 col-m-12 footer-nav-links news-nav-link'>
									<span className='item-nav material-nav'>
										<CustomLink
											className={`p2 font-montserrat-arm-medium blue-1000 materials-link underline link-item-navigation `}
											url={config.routes.usefulMaterials.path}>
											{translate(config.routes.usefulMaterials.name)}
										</CustomLink>
									</span>

									<span className='item-nav'>
										<CustomLink
											className={`p2 font-montserrat-arm-medium blue-1000 underline link-item-navigation `}
											url={config.routes.news.path}>
											{translate(config.routes.news.name)}
										</CustomLink>
									</span>
								</div>
								<div className='col-3 col-t-12 subscribe-wrapper footer-nav-links'>
									<Text className={'p2 font-montserrat-arm-medium blue-1000'}>{translate('subscribeToOurNewsletter')}.</Text>

									<Text
										text={'wantToStayUp'}
										className={'p3 font-montserrat-arm-regular blue-900 wantToStayUp'}
									/>

									<div className='contact-wrapper-footer'>
										<FormContainer
											onFinish={handleSubscribe}
											className={'form-container'}>
											<FormItem
												required={false}
												name={'subscribe_email'}
												validationType={'email'}
												className={`subscribe-field ${isSuccess === 'loading' ? 'disabled' : ''}`}>
												<FormInput
													placeholder={'emailPlaceholder'}
													prefix={
														<Button
															btnType={'default'}
															ariaLabel={{
																'aria-label': 'Aria Name',
															}}
															type={'submit'}
															className={'button-submit'}
															disabled={isSuccess === 'loading'}>
															<Icon
																name={'arrow-right'}
																className={`footer-subscribe-icon ${isSuccess === 'loading' ? 'hide' : ''}`}
															/>
														</Button>
													}
												/>
											</FormItem>

											{isSuccess === 'loading' ? (
												<div className='loader-block'>
													<ButtonLoading />
												</div>
											) : null}

											{isSuccess && isSuccess !== 'loading' ? (
												<Text
													className='p p5 orange-1000-color font-montserrat-regular subscribe-text'
													text={'subscribeSuccessText'}
												/>
											) : null}
										</FormContainer>
									</div>
								</div>
							</div>

							<div
								ref={middleFooter}
								className='wrapper-middle row'>
								<div className='col-3 col-t-4 col-m-12'>
									<Text className={'p2 font-montserrat-arm-regular blue-900'}>{globalData.address}</Text>
								</div>
								<div className='col-7 col-t-5 col-m-12'>
									<div className='row'>
										<div className='col-5 col-t-12'>
											<span className='item-nav'>
												<CustomLink
													external
													className={'p2 font-montserrat-arm-regular blue-900'}
													url={`mailto:${globalData.email}`}>
													<Text className={'link-item-navigation'}>{globalData.email}</Text>
												</CustomLink>
											</span>
										</div>
										<div className='col-6 col-t-12'>
											<span className='item-nav'>
												<CustomLink
													external
													className={'p2 font-montserrat-arm-regular blue-900 '}
													url={`tel:${globalData.phone_number}`}>
													<Text className={'link-item-navigation'}>{globalData.phone_number}</Text>
												</CustomLink>
											</span>
										</div>
									</div>
								</div>
								<div className='col-2 col-t-3 col-m-12'>
									{globalData?.socials ? (
										<div className='social-media'>
											{globalData.socials?.facebook ? (
												<span className='item-nav'>
													<CustomLink
														external
														url={globalData.socials.facebook}
														className={'footer-icon fb-icon'}>
														<Icon
															name={'fb'}
															className={`h6`}
														/>
													</CustomLink>
												</span>
											) : null}

											{globalData.socials?.youtube ? (
												<span className='item-nav'>
													<CustomLink
														external
														url={globalData.socials.youtube}
														className={'footer-icon youtube-icon'}>
														<Icon
															name={'youtube'}
															className={`h6 youtube`}
														/>
													</CustomLink>
												</span>
											) : null}

											{globalData.socials?.linkedin ? (
												<span className='item-nav'>
													<CustomLink
														external
														url={globalData.socials.linkedin}
														className={'footer-icon linkedin-icon'}>
														<Icon
															name={'linkedin'}
															className={`h6`}
														/>
													</CustomLink>
												</span>
											) : null}
										</div>
									) : null}
								</div>
							</div>
							<div className='wrapper-bottom row'>
								<div className='col-7 col-t-12'>
									<Text className={'p3 font-montserrat-arm-regular blue-900'}>
										{translate('designedAndDeveloped')}
										&nbsp;
										<span className='item-nav'>
											<CustomLink
												external
												className={'p3 underline font-montserrat-arm-medium blue-900 link-item-navigation'}
												url={'https://conceptstudio.com/'}>
												Concept Studio
											</CustomLink>
										</span>
										.
									</Text>
								</div>
								<div className='col-5 col-t-12'>
									<Text className={'p3 font-montserrat-arm-regular blue-900 text-end rights'}>
										©{new Date().getFullYear()}. {translate('allRightsReserved')}.
									</Text>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FooterStyle>
		</Resize>
	);
};

export default withDataContext(withLanguageContext(AppFooter, ['translate', 'selectedLang']), ['globalData', 'subscribe']);
