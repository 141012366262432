import styled from 'styled-components';

const BurgerMenuStyle = styled.div`
	--padNav: var(--sp2-5x);
	--radiusItemNav: var(--sp2x);
	--distanceItem: var(--sp2x);
	--sizeBurgerLottie: var(--sp3x);
	--distanceBurgerLanguage: var(--sp3x);

	.lottie-wrap {
		height: fit-content;
		line-height: 1;

		svg {
			width: var(--sizeBurgerLottie) !important;
			g {
				g {
					path {
						transition: stroke var(--trTime) ease-out;
						stroke: var(--blue900) !important;
					}
				}
			}
		}
	}

	.burger-menu-inner {
		margin-left: var(--distanceBurgerLanguage);
	}

	.menu-burger-bar {
		display: flex;
		flex-direction: column;
		gap: var(--distanceItem);
	}

	.wrapper-menu-open {
		left: 0;
		top: 0;
		position: fixed;
		width: 100%;
		height: 100vh;
		transition: transform var(--trTime) ease-out;
		transform: translateX(100vw);
		overflow: hidden;
		background-color: var(--backgroundColor);

		overflow-y: auto;
		overflow-x: hidden;

		.inner-menu {
			padding: 0 var(--contPaddingLR);
			padding-top: var(--headerFixedHeight);
			margin-top: var(--sp3x);
		}

		.inner-menu,
		.opened-menu {
			width: 100%;
			height: 100%;
		}

		&.active {
			width: 100vw;
			transform: translateX(0);
		}
	}
	.wrapper-link-burger {
		padding: var(--padNav);
		background-color: var(--white);
		border-radius: var(--radiusItemNav);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padNav: var(--sp2x);
		--radiusItemNav: calc(var(--sp1-5x) - var(--sp0-5x) / 2);
		--sizeBurgerLottie: var(--sp2x);
		--distanceBurgerLanguage: var(--sp2-5x);
	}
`;
export default BurgerMenuStyle;
