import styled from 'styled-components';

const HeaderStyle = styled.header`
	--padTB: var(--sp5x);
	--padLR: var(--sp7x);
	--distanceNavigation: var(--sp7x);
	--distanceCollapseContent: var(--sp3x);
	--leftNavigationWidth: var(--sp45x);
	--rightNavigationWidth: var(--sp57x);

	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateY(0%);
	transition: transform var(--trTime) ease-out, background-color var(--trTime) ease-out;
	padding: var(--padTB) var(--padLR);
	height: var(--headerFixedHeight);

	&.first-show {
		transform: translateY(-100%);
	}

	&.close-header {
		transform: translateY(-100%);
	}

	.inner-wrapper-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
	}

	.wrapper-content {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.inner-content {
			width: fit-content;
			display: flex;
			align-items: center;
			gap: var(--distanceNavigation);
		}
	}

	.navigation-menu {
		.wrapper-collapse {
			min-width: var(--leftNavigationWidth);
			padding-top: var(--distanceCollapseContent);
		}

		&-sme {
			.wrapper-collapse {
				min-width: var(--rightNavigationWidth);
			}
		}
	}

	.wrapper-language-burger {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.text-navigation {
		transition: color var(--trTime) ease-out;

		&.active-link {
			color: var(--lightBlue) !important;
		}
	}

	.burger-menu {
		display: none;
	}

	.wrapper-navigation-arrow {
		display: flex;
		gap: var(--distanceArrow);
	}

	&.white {
		color: var(--white) !important;

		.text-navigation {
			color: var(--white);

			svg {
				g {
					g {
						path {
							stroke: var(--white) !important;
						}
					}
				}
			}
		}

		.arrow-icon {
			color: var(--white) !important;
		}
	}

	&.blue {
		background-color: var(--backgroundColor);

		svg {
			g {
				g {
					path {
						stroke: var(--blue900) !important;
						fill: var(--blue900) !important;
					}
				}
			}
		}
	}

	&.burger-menu-open {
		color: var(--blue900) !important;

		.text-navigation {
			color: var(--blue900);

			svg {
				g {
					g {
						path {
							stroke: var(--blue900) !important;
							fill: var(--blue900) !important;
						}
					}
				}
			}
		}
	}

	.link-item {
		text-transform: capitalize;
		transition: var(--trTime) ease-out !important;
	}

	@media (hover: hover) {
		.link-item {
			&:hover {
				color: var(--lightBlue);
			}
		}

		.navigation-menu {
			&:hover {
				.wrapper-name {
					.text-navigation,
					.arrow-icon {
						&.active {
							color: var(--lightBlue) !important;
						}
					}
				}
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--padTB: var(--sp3x);
		--padLR: var(--sp5x);
		--distanceNavigation: var(--sp6x);
		--leftNavigationWidth: var(--sp41x);
		--rightNavigationWidth: var(--sp51x);
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--padTB: var(--sp2-5x);
		--padLR: var(--sp4x);
		--distanceNavigation: var(--sp5x);
		--leftNavigationWidth: var(--sp35x);
		--rightNavigationWidth: var(--sp51x);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--distanceNavigation: var(--sp4x);
		--distanceCollapseContent: var(--sp2x);
		--leftNavigationWidth: var(--sp32x);
		--rightNavigationWidth: var(--sp46x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--padTB: var(--sp3x);
		--padLR: var(--sp8x);
		--distanceCollapseContent: var(--sp1x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--padLR: var(--sp6x);
		--leftNavigationWidth: 100%;
		--rightNavigationWidth: 100%;

		.burger-menu {
			display: block;
		}

		.wrapper-content {
			display: none;
		}
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padLR: var(--sp2x);
	}
`;
export default HeaderStyle;
