'use client';

import { useCallback, useRef, useEffect, useState } from 'react';
import { useParams, usePathname } from 'next/navigation';

//* Helpers
import { config } from '@/helpers';

//* HOO'C
import { withLanguageContext, withUIContext } from '@/context';

//* Style
import HeaderStyle from './style';

//* Component
import Logo from '../Logo';
import Navigation from './Navigation';
import BurgerMenu from './BurgerMenu';
import CustomLink from '../CustomLink';
import LanguageSwitcher from './LanguageSwitcher';

const AppHeader = ({ isBurgerMenuOpen, translate, selectedLang }) => {
	//! State
	const [headerVariant, setHeaderVariant] = useState('blue');

	//! Ref
	const oldScroll = useRef(0);
	const headerRef = useRef(null);
	const firstRender = useRef(true);

	//! Router
	const pathname = usePathname();
	const params = useParams();

	//! Show / Hide Header
	const onScroll = useCallback(() => {
		if (window.scrollY > oldScroll.current && window.scrollY > 0) {
			if (headerRef.current) headerRef.current.classList.add('close-header');
		} else if (window.scrollY < oldScroll.current || window.scrollY <= 0) {
			if (headerRef.current) headerRef.current.classList.remove('close-header');
		}

		oldScroll.current = window.scrollY;
	}, []);

	//! Scroll add event listener
	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		// isBurgerMenuOpen ? document.body.classList.add('hidden') : document.body.classList.remove('hidden');

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [isBurgerMenuOpen, pathname, params.lang]);

	const activePath = pathname.replace(new RegExp(`^/${selectedLang}`), '');

	//! route variant
	const getRouteVariant = (activePath) => {
		for (const routeKey in config.routes) {
			const route = config.routes[routeKey];
			if (route.path === activePath) {
				return route.variant || null;
			}
		}
		return null;
	};
	useEffect(() => {
		const variant = getRouteVariant(activePath);

		setHeaderVariant(variant ? variant : 'blue');
	}, [pathname]);

	useEffect(() => {
		let observer;

		if (getRouteVariant(activePath) === 'white') {
			const imgElement = document.querySelector('img:not(header img)');
			const sizeHeight = document.getElementById('header').clientHeight;

			if (!imgElement) return;

			observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting) {
						setHeaderVariant('white');
					} else {
						setHeaderVariant('blue');
						if (headerRef.current) headerRef.current.classList.add('close-header');
					}
				},
				{
					rootMargin: `-${sizeHeight}px 0px 0px 0px`,
					threshold: 0,
				}
			);

			observer.observe(imgElement);
		}

		return () => {
			if (observer) observer.disconnect();
		};
	}, [headerVariant]);

	//! first show header
	useEffect(() => {
		if (headerRef.current && firstRender.current) {
			setTimeout(() => {
				firstRender.current = false;
				headerRef.current.classList.remove('first-show');
			}, 300);
		}
	}, [headerRef.current]);

	return (
		<HeaderStyle
			id={'header'}
			ref={headerRef}
			className={`revrait-color-header ${firstRender.current ? 'first-show' : ''} ${headerVariant} ${isBurgerMenuOpen ? 'burger-menu-open' : ''}`}>
			<div className='inner-wrapper-header'>
				<div className='wrapper-logo'>
					<Logo type={`${isBurgerMenuOpen ? 'blue' : headerVariant}`} />
				</div>
				<div className='wrapper-content'>
					<div className='inner-content'>
						<Navigation
							active={activePath}
							className={'navigation-menu'}
							name='icArmenia'
							data={[
								{ ...config.routes.about, name: translate(config.routes.about.name) },
								{ ...config.routes.team, name: translate(config.routes.team.name) },
								{ ...config.routes.reports, name: translate(config.routes.reports.name) },
							]}
						/>

						<Navigation
							active={activePath}
							className={'navigation-menu navigation-menu-sme'}
							name='smeDevelopment'
							data={[
								{ ...config.routes.reforms, name: translate(config.routes.reforms.name) },
								{ ...config.routes.structure, name: translate(config.routes.structure.name) },
								{ ...config.routes.contact, name: translate(config.routes.contact.name) },
								{ ...config.routes.councilMeetings, name: translate(config.routes.councilMeetings.name) },
								{ ...config.routes.sustainabilityCorner, name: translate(config.routes.sustainabilityCorner.name) },
							]}
						/>

						<CustomLink
							className={`p1 text-navigation font-montserrat-arm-medium ${activePath == config.routes.news.path ? 'active-link' : ''}`}
							url={config.routes.news.path}>
							{translate(config.routes.news.name)}
						</CustomLink>

						<CustomLink
							className={`p1 text-navigation font-montserrat-arm-medium  ${activePath == config.routes.usefulMaterials.path ? 'active-link' : ''}`}
							url={config.routes.usefulMaterials.path}>
							{translate(config.routes.usefulMaterials.name)}
						</CustomLink>
					</div>
				</div>
				<div className='wrapper-language-burger'>
					<div className='wrapper-language'>
						<LanguageSwitcher />
					</div>
					<div className='burger-menu'>
						<BurgerMenu activePath={activePath} />
					</div>
				</div>
			</div>
		</HeaderStyle>
	);
};

export default withLanguageContext(withUIContext(AppHeader, ['isBurgerMenuOpen']), ['translate', 'selectedLang']);
