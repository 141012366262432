import styled from 'styled-components';

const LogoStyle = styled.div`
	--sizeLogoDefault: 20%;
	--widthLogo: var(--sp32x);
	--footerLogo: var(--sp20x);

	position: relative;
	z-index: 1000;
	width: fit-content;

	.wrapper-logo {
		.image-cont {
			--proportion: unset;

			background-color: transparent !important;
			width: var(--widthLogo);

			img {
				position: relative !important;
				object-fit: contain;
			}
		}
	}

	.blue-footer {
		.image-cont {
			width: var(--footerLogo);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--widthLogo: var(--sp25x);
		--footerLogo: var(--sp15x);
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--widthLogo: var(--sp20x);
		--footerLogo: var(--sp12x);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--footerLogo: var(--sp11x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--widthLogo: var(--sp18x);
		--footerLogo: var(--sp10x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--widthLogo: var(--sp15x);
		--footerLogo: var(--sp9x);
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--widthLogo: var(--sp13x);
	}
`;
export default LogoStyle;
