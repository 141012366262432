import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import gsap from 'gsap/all';

//* Styles
import NavigationStyle from './style';

//* Component
import CustomLink from '@/components/CustomLink';
import Text from '@/components/Text';
import Icon from '@/components/Icon';

const Navigation = ({ name, data, className, active, isBurgerMenu }) => {
	//! State
	const [open, setOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	//! Refs
	const innerRefCollapse = useRef(null);
	const collapseRef = useRef(null);
	const selectedBar = useRef();

	//! Route
	const pathname = usePathname();

	//! Open close index
	useEffect(() => {
		if (!isBurgerMenu) {
			const currentIndex = data.findIndex((item) => item.path === active);

			setActiveIndex(0);
			//! if active index current after close
			// setActiveIndex(currentIndex >= 0 ? currentIndex : 0);
		}
	}, [open]);

	//! Open close Collapse
	useEffect(() => {
		if (!isBurgerMenu) {
			if (open) {
				collapseRef.current.classList.add('pointer-all');
				gsap.to(collapseRef.current, { height: 'auto', duration: 0.3, ease: 'power2.out' });
			} else {
				collapseRef.current.classList.remove('pointer-all');
				gsap.to(collapseRef.current, { height: 0, duration: 0.3, ease: 'power2.out' });
				gsap.to(selectedBar.current, { opacity: 0 });
			}
		}
	}, [open]);

	//! Close burger update pathname
	useEffect(() => {
		if (!isBurgerMenu) {
			collapseRef.current.classList.remove('pointer-all');
			gsap.to(collapseRef.current, { height: 0, duration: 0.3, ease: 'power2.out' });
		}
	}, [pathname]);

	//! Mouse Move Item
	function handleMouseMove(ev) {
		const parentBlock = ev.target.closest('.wrapper-link');
		const blockNumber = parentBlock.getAttribute('data-block');
		setActiveIndex(parseFloat(blockNumber));
		gsap.to(selectedBar.current, { opacity: 0.1 });
	}

	//! Translate collapse item
	useEffect(() => {
		if (!isBurgerMenu) {
			let gapDistance;
			let heightItem;
			if (innerRefCollapse.current) {
				gapDistance = parseFloat(window.getComputedStyle(innerRefCollapse.current).gap);
				heightItem = innerRefCollapse.current.children[activeIndex + 1].getBoundingClientRect().height;
			}
			if (gapDistance && heightItem) {
				selectedBar.current.children[0].style.height = heightItem + 'px';
				gsap.to(selectedBar.current, {
					duration: 0.2,
					y: `${(gapDistance + heightItem) * activeIndex}px`,
				});
			}
		}
	}, [activeIndex]);

	return (
		<NavigationStyle
			className={`${className || ''} ${isBurgerMenu ? 'burger-navigation' : ''}`}
			onMouseEnter={() => {
				setOpen(!open);
			}}
			onMouseLeave={() => {
				setOpen(!open);
			}}>
			<div className='wrapper-name'>
				<Text
					className={`p1 text-navigation font-montserrat-arm-medium  ${open ? 'active' : ''}`}
					text={name}
				/>
				{isBurgerMenu ? null : (
					<Icon
						className={`arrow-icon ${open ? 'active' : ''}`}
						name={'arrow'}
					/>
				)}
			</div>
			<div
				ref={collapseRef}
				className='wrapper-collapse'>
				<div
					ref={innerRefCollapse}
					className='inner-wrapper-collapse'>
					{isBurgerMenu ? null : (
						<div
							ref={selectedBar}
							className='selected-bar-animation opacity-0'>
							<div className='inner-no-text' />
						</div>
					)}
					{data.map((item, i) => {
						return (
							<div
								data-block={i}
								key={item.path}
								className='wrapper-link'
								onMouseMove={isBurgerMenu ? null : handleMouseMove}>
								<CustomLink
									className={`p3 font-montserrat-arm-medium every-link-collapse ${item.path == active ? 'active' : ''}`}
									url={item.path}>
									{item.name}
								</CustomLink>
							</div>
						);
					})}
				</div>
				{/* </div> */}
			</div>
		</NavigationStyle>
	);
};

export default Navigation;
