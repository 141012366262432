'use client';

import { useEffect, useState } from 'react';
import gsap, { ScrollTrigger } from 'gsap/all';
import { useGSAP } from '@gsap/react';

//* Components
import ScrollWrapper from '../ScrollWrapper';
import Page from '../Page';
import AppFooter from '../AppFooter';

const HighOrderComponent = ({ children }) => {
	//! State
	const [isTouchScreen, setIsTouchScreen] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined' && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
			setIsTouchScreen(true);
		}
	}, []);

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger);
	}, {});

	return !isTouchScreen ? (
		<ScrollWrapper>
			<Page>{children}</Page>

			<AppFooter />
		</ScrollWrapper>
	) : (
		<>
			<Page>{children}</Page>

			<AppFooter />
		</>
	);
};

export default HighOrderComponent;
