// * Styles
import { config } from '@/helpers';
import CustomLink from '../CustomLink';
import LogoStyle from './style';
import Image from '../Image';

const Logo = ({ type }) => {
	let LogoType;

	switch (type) {
		case 'blue-footer':
			LogoType = (
				<div className='wrapper-logo blue-footer'>
					<Image
						src='/logo/logo-footer-blue.svg'
						alt='Blue Footer Logo'
					/>
				</div>
			);
			break;
		case 'blue':
			LogoType = (
				<div className='wrapper-logo blue'>
					<Image
						src='/logo/logo-blue.svg'
						alt='Blue Logo'
					/>
				</div>
			);
			break;
		default:
			LogoType = (
				<div className='wrapper-logo white'>
					<Image
						src='/logo/logo-white.svg'
						alt='Default Logo'
					/>
				</div>
			);
			break;
	}

	return (
		<LogoStyle>
			<CustomLink url={config.routes.home.path}>{LogoType}</CustomLink>
		</LogoStyle>
	);
};

export default Logo;
